@import 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap';
@tailwind components;

:root {
    scrollbar-color: #424242 #f1f1f1;
    scrollbar-width: thin;
}

body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #424242;
}



@layer components {
    body span[class*='MuiCheckbox-root'] {
        @apply p-0;
    }

    body nav[class*="MuiList-root"] {
        @apply p-0 text-sm;
    }

    body nav[class*="MuiList-root"] span[class*='MuiTypography-root'] {
        @apply text-sm;
    }

    body nav[class*="MuiList-root"] div[class*='MuiListItemButton-root'] {
        @apply bg-white p-2 rounded-md shadow-md focus:outline-green;
    }

    body nav[class*="MuiList-root"] div[class*='MuiListItemText-root'] {
        @apply m-0;
    }

    .countryDD div[class*='MuiPopover-paper'] {
        @apply w-[346px];
    }

    body svg[class*='MuiSvgIcon-root'] {
        font-size: inherit;
    }

    body div[class*='MuiOutlinedInput-input'] {
        @apply py-2 text-sm rounded-lg;
        font-family: inherit;
        min-width: 80px;
    }

    body fieldset[class*='MuiOutlinedInput-notchedOutline'] {
        @apply border-green !important;
        @apply shadow-md rounded-lg;
    }

    body .chartDD fieldset[class*='MuiOutlinedInput-notchedOutline'] {
        @apply shadow-xs;
    }

    body li[class*='MuiMenuItem-root'] {
        @apply text-sm;
    }

    body div[class*='MuiTabs-flexContainer'] {
        @apply gap-3 lg:gap-10 flex-wrap;
    }

    body button[class*='MuiButtonBase-root'] {
        @apply p-0 text-sm normal-case tracking-normal min-h-8;
        font: inherit;
    }

    body div[class*='MuiTabs-root'] {
        @apply min-h-8;
    }

    body .backBtn svg[class*='MuiSvgIcon-root'] {
        @apply text-xl;
    }

    .hdrAccMenu .MuiPopover-paper {
        @apply rounded-lg mt-2;
    }
    .dataTblWrap table td,
    .dataTblWrap table th {
        @apply px-3 py-2 border-r border-slate text-sm text-zinc text-center;
    }
    .dataTblWrap table td:last-child,
    .dataTblWrap table th:last-child {
        @apply border-r-0;
    }
}


body div[class*='MuiTabs-flexContainer'] {
    @apply justify-center;
}

body button[class*='MuiButtonBase-root'].Mui-selected {
    @apply text-green font-bold;
}

body span[class*='MuiTabs-indicator'] {
    @apply bg-green;
}

body li[class*='MuiMenuItem-root'].Mui-selected {
    @apply bg-green !important;
    @apply text-white;
}



.hdrAccMenu .MuiPopover-paper {
    @apply bg-green text-white;
}

.hdrAccMenu .MuiPopover-paper li::after {
    content: '';
    width: 82%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.hdrAccMenu .MuiPopover-paper li:last-child::after {
    display: none;
}

body div[class*='MuiPopover-paper'] {
    max-height: calc(100vh - 230px) !important;
}



.ttBox:hover>.ttBoxTxtPane {
    display: block;
}

.ttBox .ttBoxTxtPane {
    position: absolute;
    display: none;
    left: 0;
    top: 100%;
    z-index: 99;
    width: 220px;
}

.infoIco {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: transparent;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../public/images/svg/info_icon.svg');
}

.ttBox .ttBoxTxtPane .ttBoxPaneInner {
    display: block;
    width: 100%;
    background-color: #ffffff;
    border: solid 1px #cccccc;
    -webkit-filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.4));
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.4));
    padding: 6px 10px;
    font-weight: 400;
    color: #212121;
    line-height: 1.3;
    position: relative;
    text-align: left;
}

.ttBoxTxtPane .ttBoxPaneInner::before,
.ttBoxTxtPane .ttBoxPaneInner::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 3px;
}

.ttBoxTxtPane .ttBoxPaneInner::before {
    border-bottom: 10px solid #cccccc;
    bottom: 100%;
    z-index: 8;
}

.ttBoxTxtPane .ttBoxPaneInner::after {
    border-bottom: 10px solid #ffffff;
    bottom: calc(100% - 1px);
    z-index: 9;
}


body .Toastify__toast--default,
body .Toastify__toast--info,
body .Toastify__toast--success,
body .Toastify__toast--warning,
body .Toastify__toast--error {
    @apply text-black text-sm;
}

body .Toastify__progress-bar--success {
    @apply bg-green;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s !important;
    -webkit-appearance: none !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
}

input:-moz-autofill,
:-moz-autofill-preview {
    filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);    
    -moz-appearance: none !important;
}

input:autofill {
    background-color: rgb(255 255 255) !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
    transition: background-color 5000000s ease-in-out 0s !important;
}

.menuNav button {
    @apply relative;
}
.menuNav button.active {
    @apply bg-white text-green font-bold;
}
.menuNav button.active::before {
    content: '';
    @apply absolute w-[3px] h-[80%] bg-green left-0 top-[50%] translate-y-[-50%];
}

/* Inline - Loader */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 46px;
    height: 46px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    margin: 2px;
    border: 2px solid #9f9f9f;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #9f9f9f transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  .yrSldr .slick-slider .slick-arrow::before {
    @apply hidden;
  }
  .yrSldr .slick-slider .slick-arrow {
    background-image: url('../public/images/svg/chevron-left.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .yrSldr .slick-slider .slick-arrow.slick-prev {
    left: 0;
  }
  .yrSldr .slick-slider .slick-arrow.slick-next {
    right: 0;
    -webkit-transform: translate(0, -50%) scaleX(-1);
    transform: translate(0, -50%) scaleX(-1);
  }

  .yrSldr.top .slick-slider .slick-arrow.slick-prev,
  .yrSldr.top .slick-slider .slick-arrow.slick-next {
    top: 14px;
    z-index: 10;
  }  
  .yrSldr.top .slick-slider .slick-arrow.slick-next {
    right: 0;
  }


  .qtrFltrInputs input:checked + label.text-zinc {
    @apply text-green;
  }

.slice:not(.cursor-pointer) .slicetext > text > tspan:nth-child(2) {
    display: none;
}

.topStatBar > .bg-white {
    @apply border-l-4 border-green rounded-r-md;
}

body .grow.p-4 div[class*='MuiOutlinedInput-input'] {
    font-size: 13px;
}

body .chartDD.mxWdth div[class*='MuiOutlinedInput-input'] {
    @apply max-w-24 whitespace-nowrap text-ellipsis overflow-hidden;
}
div.disabled {
    pointer-events: none;
    position: relative;
    
}
div.disabled::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
div.disabled label.text-black {
    color: #9e9d9d;
}