/* Commonly used styles */
@tailwind components;

@layer components {
    .tableWrap table {
        @apply table-auto w-full text-center text-sm text-zinc relative;
    }

    .tableWrap table thead {
        @apply sticky left-0 top-0 bg-white shadow-lg border-t border-light;
    }

    .tableWrap table thead th {
        @apply border-t-0;
    }

    .tableWrap table th,
    .tableWrap table td {
        @apply border border-solid border-slate p-2 break-words;
    }

    .tableWrap table th:first-child,
    .tableWrap table td:first-child {
        @apply border-l-0;
    }

    .tableWrap table th:last-child,
    .tableWrap table td:last-child {
        @apply border-r-0;
    }

    .error_field {
        @apply border-2 border-solid border-red;
    }

    .itemBox::after {
        @apply absolute h-3/4 w-full left-0 bottom-0 z-0 bg-white shadow-xs rounded-2xl;
        content: '';
    }

    .itemBox .cImg {
        @apply rounded-full shadow-xs shadow-2xl object-cover inline-block;
        width: 100px;
        height: 100px;
    }

    .cImg[alt]:after {
        background-image: url("../../public/images/svg/dishes-ico.svg");
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #c2c2c2;
        font-family: 'Helvetica';
        font-weight: 300;
        line-height: 2;
        text-align: center;
        content: attr(alt);
    }


    .ddRatings {
        @apply rounded-md bg-white border-gray shadow-xl absolute top-[108%] left-0 z-50 px-3 py-2 w-full;
    }

    .ddYearBox {
        @apply rounded-md bg-white border-gray shadow-xl absolute top-[104%] left-[50%] translate-x-[-50%] z-50 w-60;
    }

    .ddRatings.up {
        @apply top-[auto] bottom-[108%];
    }

    .tLDDSBox {
        @apply rounded-md bg-white border-gray shadow-xl absolute top-[104%] left-[50%] translate-x-[-50%] z-50 overflow-hidden;
    }

    .dpHldr {
        @apply size-24 rounded-full relative m-auto border border-green bg-white flex items-center justify-center text-green text-6xl mb-4;
    }
}


@screen md {
    .logBox {
        overflow: visible;
    }

    .tableWrap {
        max-height: calc(100vh - 90px);
    }
}

/* Media screens - End */

.hdrShade {
    box-shadow: 0 19px 42px 0 rgba(0, 0, 0, 0.3);
}

.star img {
    width: 14px;
}

.defaultBg {
    background-position: center;
    background-repeat: no-repeat;
    @apply mx-auto mb-4 bg-slate rounded-full;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    color: transparent;
    position: relative;
}

.pixAttr {
    margin: 3px 15px 5px 0;
    position: fixed;
    z-index: 100;
    right: -60px;
    bottom: 80px;
    font-size: 12px;
    line-height: 1.7;
    color: #555;
    display: flex;
    align-items: center;
    padding: 9px 12px 6px;
    border: 1px solid #ccc;
    background-color: #fff;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.3));
    transform: rotate(-90deg);
}

.pixI {
    display: block;
    width: 20px;
    height: 18px;
    margin-right: 10px;
}

.pixImg {
    width: 20px
}